import React from "react"
import styled from "styled-components"

import Navbar from '../components/navbar'
const BlogLayout = (props) => {

  const { children } = props;

  return (
    <Wrapper>
      <div>
        <Navbar blog={true} />
        <main style={{ zIndex: 1 }}>{children}</main>
      </div>
      <Footer>
        © {new Date().getFullYear()}, Estevan Jantsk
      </Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default BlogLayout
